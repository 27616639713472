@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  .mat-mdc-tooltip {

    &.tooltip-menu {
      transform-origin: left center;
      margin: 2px 0 2px -2px;
      height: 50px;
      border-radius: 0;
      width: 200px;
      font-size: 20px;
      align-items: center;
      display: flex;
      place-content: center flex-start;
      background: var(--app-color-background);
      color: var(--app-color-primary);
      border: 1px solid var(--app-color-primary);
      border-left: none;
    }
  }
}
