/** @format */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use './theme/mixins' as mixins;
@use './theme/colors' as colors;
@use './theme/scrollbar' as scrollbar;
@use './theme/card-theme' as card;
@use './theme/menu-theme' as menu;
@use './theme/table-theme' as table;
@use './theme/quill-theme' as quill;
@use './theme/label-theme' as label;
@use './theme/icon-theme' as icon;
@use './theme/button-theme' as button;
@use './theme/dialog-theme' as dialog;
@use './theme/tooltip-theme' as tooltip;
@use './theme/select-theme' as select;
@use './theme/drag-theme' as drag;
@use './theme/form-field-theme' as formField;
@use './theme/file-drop-theme' as fileDrop;
@use './theme/tabs-theme' as tabs;
@use './theme/fab-menu-theme' as fabMenu;
@use './theme/chip-theme' as chip;
@use './theme/badge-theme' as badge;
@use './theme/expansion-panel-theme' as expansionPanel;
@use './theme/auto-complete-theme' as autoComplete;
@use './theme/snack-bar-theme' as snackBar;
@use './theme/slider-theme' as slider;
@use './theme/checkbox-theme' as checkbox;

$sesio-typography: mat.m2-define-typography-config(
  $font-family: "'Lato', sans-serif",
);

@include mat.all-component-typographies($sesio-typography);
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $sesio-primary: mat.define-palette(mat.$indigo-palette);
// $sesio-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// $sesio-warn: mat.define-palette(mat.$red-palette);
$sesio-primary: mat.m2-define-palette(
  (
    50: #e1e5e8,
    100: #b5bfc5,
    200: #84949e,
    300: #536977,
    400: #2e4959,
    500: #09293c,
    600: #082436,
    700: #061f2e,
    800: #051927,
    900: #020f1a,
    A100: #58a0ff,
    A200: #2583ff,
    A400: #0067f1,
    A700: #005dd8,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  )
);

$sesio-accent: mat.m2-define-palette(
  (
    50: #fff0e0,
    100: #ffd9b3,
    200: #ffbf80,
    300: #ffa54d,
    400: #ff9226,
    500: #ff7f00,
    600: #ff7700,
    700: #ff6c00,
    800: #ff6200,
    900: #ff4f00,
    A100: #ffffff,
    A200: #fff5f2,
    A400: #ffcfbf,
    A700: #ffbca6,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #ffffff,
    ),
  )
);

$sesio-warn: mat.m2-define-palette(
  (
    50: #fce6eb,
    100: #f8bfce,
    200: #f395ad,
    300: #ee6b8c,
    400: #eb4b73,
    500: #e72b5a,
    600: #e42652,
    700: #e02048,
    800: #dd1a3f,
    900: #d7102e,
    A100: #ffffff,
    A200: #ffd1d7,
    A400: #ff9eaa,
    A700: #ff8593,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #000000,
    ),
  )
);

$sesio-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $sesio-primary,
      accent: $sesio-accent,
      warn: $sesio-warn,
    ),
  )
);

@include mat.all-component-themes($sesio-theme);
@include card.theme($sesio-theme);
@include menu.theme($sesio-theme);
@include table.theme($sesio-theme);
@include quill.theme($sesio-theme);
@include label.theme($sesio-theme);
@include icon.theme($sesio-theme);
@include button.theme($sesio-theme);
@include dialog.theme($sesio-theme);
@include tooltip.theme($sesio-theme);
@include select.theme($sesio-theme);
@include drag.theme($sesio-theme);
@include formField.theme($sesio-theme);
@include fileDrop.theme($sesio-theme);
@include tabs.theme($sesio-theme);
@include fabMenu.theme($sesio-theme);
@include chip.theme($sesio-theme);
@include badge.theme($sesio-theme);
@include expansionPanel.theme($sesio-theme);
@include autoComplete.theme($sesio-theme);
@include snackBar.theme($sesio-theme);
@include slider.theme($sesio-theme);
@include checkbox.theme($sesio-theme);

:root {
  --app-font-family: Roboto, sans-serif;
  --app-font-weight-thin: 100;
  --app-font-weight-light: 300;
  --app-font-weight-regular: 400;
  --app-font-weight-medium: 500;
  --app-font-weight-bold: 700;
  --app-font-weight-black: 900;
}

html,
body {
  height: 100%;
  background-color: var(--app-color-background);
  overflow: hidden;
}
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

mat-drawer.mat-drawer {
  background-color: var(--app-color-background);
}

*[cursor-pointer] {
  cursor: pointer;
}

// TODO theme
.mat-datepicker-content {
  box-shadow:
    0 2px 4px -1px #0003,
    0 4px 5px #00000024,
    0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de;
}
.mat-calendar-body-selected {
  background-color: #007f51;
  color: #fff;
}
.mat-calendar-body-cell-content {
  border: none !important;
}

angular-org-chart .ng13-org-chart-entity-box {
  background-color: unset;
  border-radius: unset;
  border: unset;
  box-shadow: unset !important;
  min-width: unset;
  min-height: unset;
  overflow: visible;
}

#page {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

ng-scrollbar {
  & > .ng-scrollbar-wrapper {
    &[track='vertical'] {
      & > .ng-scroll-viewport-wrapper {
        & > .ng-scroll-viewport {
          & > .ng-scroll-content {
            display: inline-block;
            min-width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.sentry-error-embed-wrapper {
  z-index: 1000 !important;
}

.a-loader-title {
  color: transparent;
}

ngx-mat-datatable {
  .ngx-mat-datatable-operator-button,
  .ngx-mat-datatable-time-unit-button {
    background-color: white !important;
    color: var(--app-color-primary) !important;
    border: 1px solid var(--app-color-primary) !important;
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
  #paginator {
    .mat-mdc-form-field {
      margin-top: 0 !important;
      .mat-mdc-form-field-infix {
        padding: 4px 0;
        mat-select {
          height: 24px;
        }
      }
    }
  }
}

.chart-viewport {
  .highcharts-range-selector-buttons {
    .highcharts-button {
      .highcharts-button-box {
        fill: var(--app-color-secondary) !important;
      }
      text {
        fill: var(--app-color-secondary-contrast) !important;
      }
      &.highcharts-button-disabled {
        opacity: 0.4;
      }
    }
  }

  .highcharts-reset-zoom {
    rect {
      height: 28px;
      width: 28px;
    }

    text {
      transform: translate(-5px, 7px);
    }
  }
}
