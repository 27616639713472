/** @format */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  mat-expansion-panel.mat-expansion-panel {
    .mat-expansion-panel-header-title {
      padding: 2px 0;
    }
    &[no-padding] {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }
}
