/** @format */

@mixin theme($theme) {
  mat-icon.mat-icon {
    color: currentColor;
    &[color],
    &[ng-reflect-color] {
      color: var(--app-item-color, currentColor);
    }

    width: var(--mdc-icon-size, 24px);
    height: var(--mdc-icon-size, 24px);
    min-width: var(--mdc-icon-size, 24px);
    min-height: var(--mdc-icon-size, 24px);
    line-height: var(--mdc-icon-size, 24px);
    font-size: var(--mdc-icon-size, 24px);

    &[large],
    &[size='huge'] {
      --mdc-icon-size: 44px;
    }

    &[large],
    &[size='large'] {
      --mdc-icon-size: 28px;
    }

    &[small],
    &[size='small'] {
      --mdc-icon-size: 20px;
    }

    &[xsmall],
    &[size='xsmall'] {
      --mdc-icon-size: 16px;
    }
  }

  .mat-step-icon mat-icon.mat-icon {
    min-width: 16px;
    min-height: 16px;
    line-height: 16px;
  }

  mat-icon.spin {
    animation: spin 2s infinite linear;
  }

  mat-icon[fill],
  .material-symbols-outlined[fill] {
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
