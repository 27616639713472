/** @format */
@use 'sass:math';
@use 'sass:color';
@use 'mixins';

@function color-contrast($color) {
  $color-brightness: math.round(
    math.div(
      (color.channel($color, 'red', $space: rgb) * 299)+ (color.channel($color, 'green', $space: rgb) * 587)+
        (color.channel($color, 'blue', $space: rgb) * 114),
      1000
    )
  );
  $light-color: math.round(
    math.div(
      (color.channel(#ffffff, 'red', $space: rgb) * 299)+ (color.channel(#ffffff, 'green', $space: rgb) * 587)+
        (color.channel(#ffffff, 'blue', $space: rgb) * 114),
      1000
    )
  );
  @if math.abs($color-brightness) < math.div($light-color, 1.265) {
    @return white;
  } @else {
    @return black;
  }
}

@mixin declareColor($name, $color) {
  $contrast: color-contrast($color);
  --mat-sys-#{$name}: #{$color};
  --app-color-#{$name}: #{$color};
  --app-color-#{$name}-rgb: #{color.channel($color, 'red', $space: rgb)},
    #{color.channel($color, 'green', $space: rgb)}, #{color.channel($color, 'blue', $space: rgb)};
  --app-color-#{$name}-50: #{rgba(
      color.channel($color, 'red', $space: rgb),
      color.channel($color, 'green', $space: rgb),
      color.channel($color, 'blue', $space: rgb),
      0.5
    )};
  --app-color-#{$name}-25: #{rgba(
      color.channel($color, 'red', $space: rgb),
      color.channel($color, 'green', $space: rgb),
      color.channel($color, 'blue', $space: rgb),
      0.25
    )};
  --app-color-#{$name}-10: #{rgba(
      color.channel($color, 'red', $space: rgb),
      color.channel($color, 'green', $space: rgb),
      color.channel($color, 'blue', $space: rgb),
      0.1
    )};
  --mat-sys-on-#{$name}: #{$contrast};
  --app-color-#{$name}-contrast: #{$contrast};
  --app-color-#{$name}-contrast-rgb: #{color.channel($contrast, 'red', $space: rgb)},
    #{color.channel($contrast, 'green', $space: rgb)}, #{color.channel($contrast, 'blue', $space: rgb)};
}

@mixin declareThemeColor($name, $color) {
  @include declareColor($name, $color);
  [color='#{$name}'],
  .mat-#{$name} {
    @include mixins.setColor($name);
  }

  $shadeColor: color.mix(black, $color, 10%);
  @include declareColor(#{$name}-shade, $shadeColor);
  [color='#{$name}-shade'],
  .mat-#{$name}-shade {
    @include mixins.setColor(#{$name}-shade);
  }

  $tintColor: color.mix(white, $color, 10%);
  @include declareColor(#{$name}-tint, $tintColor);
  [color='#{$name}-tint'],
  .mat-#{$name}-tint {
    @include mixins.setColor(#{$name}-tint);
  }
}

:root {
  --app-color-background: #eeeeee;
  --app-text-color: var(--app-color-primary);

  @include declareThemeColor(primary, #09293c);
  @include declareThemeColor(accent, #ff7f00);
  @include declareThemeColor(warn, #e72b5a);
  @include declareThemeColor(danger, #e9396b);
  @include declareThemeColor(secondary, #21698e);
  @include declareThemeColor(tertiary, #5260ff);
  @include declareThemeColor(success, #7ac38e);
  @include declareThemeColor(online, #7ac38e);
  @include declareThemeColor(connected, #7ac38e);
  @include declareThemeColor(on, #7ac38e);
  @include declareThemeColor(ok, #7ac38e);
  @include declareThemeColor(warning, #e6a93a);
  @include declareThemeColor(critical, #ff7f00);
  @include declareThemeColor('dark', #222428);
  @include declareThemeColor('medium', #92949c);
  @include declareThemeColor('light', #f4f5f8);
  @include declareThemeColor('white', #ffffff);
  @include declareThemeColor('black', #021831);
  @include declareThemeColor(state-star, #f8c31d);
  @include declareThemeColor(green-energy, #79b752);
  @include declareThemeColor(nav-active, #fb506f);
  @include declareThemeColor(maintenance, #ffd238);
  @include declareThemeColor(reparation, #e6a93a);
  @include declareThemeColor(failure, #e7235b);
  @include declareThemeColor(anomaly, #ff7f00);
  @include declareThemeColor(suspicion-out-of-order, #eabac2);
  @include declareThemeColor(out-of-order, #e7235b);
  @include declareThemeColor(offline, #e7235b);
  @include declareThemeColor(noflow, #9564e2);
  @include declareThemeColor(disconnected, #9564e2);

  @include declareThemeColor(no-pressure, #ffd238);
  @include declareThemeColor(low-pressure, #47abe3);
  @include declareThemeColor(disturbed, #ffd238);

  @include declareThemeColor(real-estate-assets, #09293c);
  @include declareThemeColor(air-quality, #47abe3);
  @include declareThemeColor(energy, #79b752);
  @include declareThemeColor(ventilation-and-air-conditioning, #0d9e8a);
  @include declareThemeColor(elevator, #21698e);
  @include declareThemeColor(parking-door, #A14646);
  @include declareThemeColor(fire-safety, #e7235b);
  @include declareThemeColor(safety-and-access-control, #ff7f00);
  @include declareThemeColor(iot, #9e61ea);

  @include declareThemeColor(supervision, #222428);
  @include declareThemeColor(energy-tag, #f99444);
  @include declareThemeColor(climat-tag, #7028d2);
  @include declareThemeColor(green-energy-tag, #32a54a);
  @include declareThemeColor(stopped, #000000);
  @include declareThemeColor(off, #92949c);
  @include declareThemeColor(initializing, #92949c);
  @include declareThemeColor(nd, #92949c);

  @include declareThemeColor(fair, #ffd440);

  @include mixins.setColor(primary);

  --app-font-family: Roboto, sans-serif;
  --app-font-weight-thin: 100;
  --app-font-weight-light: 300;
  --app-font-weight-regular: 400;
  --app-font-weight-medium: 500;
  --app-font-weight-bold: 700;
  --app-font-weight-black: 900;
}
