/** @format */

@use 'mixins';

@mixin theme($theme) {
  .mat-mdc-icon-button,
  .mat-mdc-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button,
  .mat-mdc-menu-item,
  .mat-button-toggle-appearance-standard {
    --mdc-protected-button-label-text-color: var(--app-item-color);
    --mdc-text-button-label-text-color: var(--app-item-color);
    --mdc-outlined-button-label-text-color: var(--app-item-color);
    --mdc-outlined-button-outline-color: var(--app-item-color);
    --mdc-icon-button-icon-color: var(--app-item-color);
    --mat-menu-item-label-text-color: var(--app-item-color);
    --mat-menu-item-icon-color: var(--app-item-color);

    --mat-standard-button-toggle-text-color: var(--app-item-color);
    --mat-standard-button-toggle-background-color: var(--app-item-color-contrast);
    --mat-standard-button-toggle-selected-state-text-color: var(--app-item-color-contrast);
    --mat-standard-button-toggle-selected-state-background-color: var(--app-item-color);

    --mdc-fab-container-color: var(--app-item-color);
    --mdc-fab-icon-color: var(--app-item-color-contrast);

    --mdc-fab-small-container-color: var(--app-item-color);

    --mdc-icon-button-disabled-icon-color: rgba(var(--app-item-color-rgb), 0.38);

    &[disabled='true'] {
      * {
        color: rgba(var(--app-item-color-rgb), 0.38);
      }
    }

    &.mat-mdc-mini-fab {
      mat-icon {
        color: var(--mdc-fab-icon-color);
      }
    }

    &[reversed-color],
    &.reversed-color {
      &:not(:hover) {
        @include mixins.reverseColor();
        background-color: var(--app-item-color-contrast);
      }
    }

    &[map-button] {
      border-radius: 0;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      --mdc-fab-small-container-color: white !important;
      --mdc-fab-icon-color: black !important;
      --mdc-icon-button-state-layer-size: 29px;
      --mdc-icon-button-icon-size: 20px;
      --mdc-icon-size: 20px;
      padding: 6px;
    }
  }

  .mat-mdc-raised-button {
    --mdc-protected-button-container-color: var(--app-item-color);
    --mdc-protected-button-label-text-color: var(--app-item-color-contrast);
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    &:hover {
      --mat-standard-button-toggle-selected-state-text-color: var(--app-item-color-contrast);
      --mat-standard-button-toggle-selected-state-background-color: var(--app-item-color-tint);
    }
  }

  button.mat-mdc-button-base {
    &[fill] {
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
    &[vfill] {
      height: 100%;
      border-radius: 0;
    }
    &[hfill] {
      width: 100%;
      border-radius: 0;
    }
  }

  button.mat-mdc-fab,
  button.mat-mdc-mini-fab,
  button.mat-mdc-icon-button {
    --mdc-icon-button-state-layer-size: 48px;
    --mdc-icon-button-icon-size: 24px;
    --mdc-icon-size: 24px;

    .mat-mdc-button-touch-target {
      height: var(--mdc-icon-button-state-layer-size, 48px);
      width: var(--mdc-icon-button-state-layer-size, 48px);
    }

    &[size='small'] {
      --mdc-icon-button-state-layer-size: 32px;
      --mdc-icon-button-icon-size: 20px;
      --mdc-icon-size: 20px;
      padding: 6px;
    }

    &[size='xsmall'] {
      --mdc-icon-button-state-layer-size: 20px;
      --mdc-icon-button-icon-size: 16px;
      --mdc-icon-size: 16px;
      padding: 2px;
    }

    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);

    &[no-shadow] {
      box-shadow: unset;
    }

    mat-icon {
      display: flex;
    }
  }

  button.mat-mdc-raised-button,
  button.mat-mdc-button {
    &[size='small'] {
      font-size: 14px;
      line-height: 30px;
      --mdc-icon-size: 20px;
    }

    &[size='xsmall'] {
      font-size: 12px;
      line-height: 26px;
      --mdc-icon-size: 16px;
    }
  }
}
