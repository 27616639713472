/** @format */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  mat-card.mat-mdc-card {
    margin: var(--card-margin, 8px);

    mat-card-header.mat-mdc-card-header {
      width: 100%;
      .mat-mdc-card-header-text {
        width: 100%;
      }
    }

    &.mat-elevation-z2 {
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    }
    &.mat-elevation-z4 {
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
    &.mat-elevation-z6 {
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
        0px 1px 18px 0px rgb(0 0 0 / 12%);
    }
    &.mat-elevation-z8 {
      box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
        0px 3px 14px 2px rgb(0 0 0 / 12%);
    }

    &[label] {
      position: relative;
      border: 1px solid #ccc;

      &:hover,
      &[error] {
        transition: border-color 0.2s linear;
      }

      &:hover:not([error]) {
        border-color: black;
      }

      &[error] {
        border-color: var(--app-color-warn);

        &:before {
          color: var(--app-color-warn);
        }

        &:after {
          transition: line-height 0.2s linear, opacity 0.2s linear;
          line-height: 1.125;
          opacity: 1;
        }
      }

      &:before {
        content: attr(label);
        position: absolute;
        font-size: 12px;
        line-height: 12px;
        z-index: 2;
        top: -6px;
        left: 10px;
        color: rgba(0, 0, 0, 0.6);
        background-color: white;
        padding: 0 4px;
      }

      &:after {
        content: attr(error);
        position: absolute;
        right: 5px;
        bottom: 15px;
        height: 0px;
        color: var(--app-color-warn);
        font-size: 75%;
        line-height: 0;
        opacity: 0;
      }

      &[required]:not([required='false']):before {
        content: attr(label) ' *';
      }

      &[color='primary'] {
        &:before {
          color: var(--app-color-primary);
        }
      }
    }

    &.no-padding {
      .mat-mdc-card-header,
      .mat-mdc-card-content,
      .mat-mdc-card-actions {
        padding: 0;
      }
    }

    &.small-padding {
      .mat-mdc-card-header {
        padding: 8px 8px 0;
      }
      .mat-mdc-card-content {
        padding: 0 8px;
      }
      .mat-mdc-card-actions {
        padding: 4px;
      }
    }
  }
}
