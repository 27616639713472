/** @format */

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin theme($theme) {
  // Define any styles affected by the theme.
  mat-select.mat-mdc-select {
    &[color='primary'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-primary);
      }
    }

    &[color='secondary'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-secondary);
      }
    }

    &[color='success'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-success);
      }
    }

    &[color='elevator'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-elevator);
      }
    }

    &[color='parking-door'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-parking-door);
      }
    }

    &[color='relay-antenna'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-relay-antenna);
      }
    }

    &[color='energy'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-energy);
      }
    }

    &[color='fire-safety'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-fire-safety);
      }
    }

    &[color='medium'] {
      .mat-mdc-select-value-text {
        color: var(--app-color-medium);
      }
    }
  }

  mat-select.mat-mdc-select {
    mat-select-trigger {
      display: flex;
      flex-flow: column;
      padding: 4px 0;
      line-height: 1em;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      .sup {
        line-height: 1em;
        font-size: 0.8em;
      }
    }
  }

  .mat-mdc-select-panel.user-organization-select {
    overflow: visible;
    padding: 0 !important;
    margin: 8px 0 !important;
    box-shadow: none !important;

    mat-option {
      background-color: white !important;
      width: 160px;
      transform: translateX(calc(-50% + ((var(--select-width) * 1px) / 2)));
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
      &:first-child {
        border-top: 1px solid #eee;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom: 1px solid #eee;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  mat-option.mat-mdc-option.full-width {
    .mdc-list-item__primary-text {
      width: 100%;
    }
  }
}
