/** @format */

@mixin theme($theme) {
  .mat-mdc-tab:not(.mat-mdc-tab-disabled),
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) {
    --mdc-tab-indicator-active-indicator-color: var(--app-color-warn);
    --mat-tab-header-active-focus-indicator-color: var(--app-color-warn);
    --mat-tab-header-active-hover-indicator-color: var(--app-color-warn);
  }

  mat-tab-group.mat-mdc-tab-group {
    &:not([no-border]) .mat-mdc-tab-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .mat-mdc-tab .mdc-tab__text-label,
  .mat-mdc-tab-link .mdc-tab__text-label {
    color: var(--app-color-secondary);
  }

  mat-tab-group.mat-mdc-tab-group[hide-disable] {
    &
      > mat-tab-header.mat-mdc-tab-header
      > .mat-mdc-tab-label-container
      > .mat-mdc-tab-list
      > .mat-mdc-tab-labels
      > .mat-mdc-tab-label {
      padding: 0 16px;
      min-width: 140px;

      &[aria-disabled='true'] {
        display: none;
      }
    }
  }

  mat-tab-group.mat-mdc-tab-group[no-label-padding] {
    &
      > mat-tab-header.mat-mdc-tab-header
      > .mat-mdc-tab-label-container
      > .mat-mdc-tab-list
      > .mat-mdc-tab-labels
      > .mat-mdc-tab-label {
      padding: 0;
    }
  }

  mat-tab-group.mat-mdc-tab-group[sticky-header] {
    .mat-mdc-tab-header {
      position: sticky;
      top: 0;
      z-index: 10;
      background: var(--header-background, white);
    }
  }

  mat-tab-group.mat-mdc-tab-group[fill] {
    width: 100%;
    height: 100%;
    .mat-mdc-tab-body-wrapper {
      height: 100%;
      width: 100%;
    }
  }

  mat-tab-group.mat-mdc-tab-group[mat-align-links='space-arround'] {
    .mat-mdc-tab-links {
      place-content: center space-around;
    }
  }

  mat-tab-group.mat-mdc-tab-group[mat-align-links='start'] {
    .mat-mdc-tab-links {
      place-content: center start;
    }
  }

  mat-tab-group.mat-mdc-tab-group[tab-icon] {
    .mat-mdc-tab-label {
      min-width: 56px !important;
      padding: 0 16px !important;
      &.mat-mdc-tab-label-active {
        color: var(--app-color-primary);
      }
    }
  }

  mat-tab-group.mat-mdc-tab-group[no-overflow] {
    .mat-mdc-tab-body-content {
      overflow: hidden;
    }
  }
  mat-tab-group.mat-mdc-tab-group[icon-label] {
    .mat-mdc-tab-label {
      padding: 0 8px;
      min-width: 48px;
      &.mat-mdc-tab-label-active {
        mat-icon.mat-icon {
          color: var(--icon-active-color, var(--app-color-primary));
        }
      }
    }

    &[float-label] {
      mat-tab-header.mat-mdc-tab-header {
        width: fit-content;
        position: absolute;
        z-index: 10;
        border: none;
      }
    }
  }

  nav.mat-mdc-tab-nav-bar {
    background-color: #f5f5f5;

    .mat-mdc-tab-link {
      color: inherit;
      text-decoration: none;

      &.mdc-tab-indicator--active {
        color: var(--indicator-color, var(--app-color-primary));
      }
    }

    &:not([no-border]).mat-mdc-tab-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  mat-tab-nav-panel.mat-mdc-tab-nav-panel {
    // display: block;
    display: flex;
    max-height: 100%;
    height: 100%;
    min-width: 100%;
    width: 100%;
    overflow: hidden;

    & > .ng-star-inserted {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: block;
    }
  }

  mat-tab-group.mat-mdc-tab-group[tab-dialog] {
    .mat-mdc-tab.tab-dialog-close {
      margin-left: auto;
      padding: 0;
      min-width: inherit;

      &:hover .mdc-tab__ripple::before {
        opacity: 0;
      }
    }
  }
}
