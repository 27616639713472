/** @format */

@mixin setColor($name) {
  --app-color-name: #{$name};
  --app-color: var(--app-color-#{$name});
  --app-color-rgb: var(--app-color-#{$name}-rgb);

  --app-color-contrast: var(--app-color-#{$name}-contrast);
  --app-color-contrast-rgb: var(--app-color-#{$name}-contrast-rgb);

  --app-color-shade: var(--app-color-#{$name}-shade);
  --app-color-tint: var(--app-color-#{$name}-tint);

  --app-item-color: var(--app-color);
  --app-item-color-rgb: var(--app-color-rgb);
  --app-item-color-contrast: var(--app-color-contrast);
  --app-item-color-contrast-rgb: var(--app-color-contrast-rgb);
  --app-item-color-shade: var(--app-color-shade);
  --app-item-color-tint: var(--app-color-tint);

}

@mixin reverseColor() {
  --app-item-color: var(--app-color-contrast);
  --app-item-color-rgb: var(--app-color-contrast-rgb);
  --app-item-color-contrast: var(--app-color);
  --app-item-color-contrast-rgb: var(--app-color-rgb);
}
