/** @format */

@mixin theme($theme) {
  .mat-badge.full-badge {
    .mat-badge-content {
      width: auto;
      top: -14px;
      padding: 0 6px;
      border-radius: 10px;
      right: -24px;
    }
  }
}
