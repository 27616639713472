/** @format */

@use 'mixins';

@mixin theme($theme) {
  .mat-mdc-chip {
    --mdc-chip-elevated-container-color: var(--app-item-color);
    --mdc-chip-label-text-color: var(--app-item-color-contrast);
    --mdc-chip-with-trailing-icon-trailing-icon-color: var(--app-item-color-contrast);

    &[reversed-color],
    &.reversed-color {
      border: 1px solid var(--app-item-color-contrast);
      @include mixins.reverseColor();
    }

    &[disableHover] {
      &:hover {
        .mat-mdc-chip-focus-overlay {
          opacity: 0;
        }
      }
    }
  }

  .mat-mdc-chip-remove {
    line-height: 1em;

    .mat-icon {
      min-width: inherit;
      min-height: inherit;
      line-height: inherit;
      font-size: inherit;
    }
  }
}
