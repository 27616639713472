/** @format */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  mat-snack-bar-container.mat-mdc-snack-bar-container {
    &.notification-snack-bar {
      --mdc-snackbar-container-color: transparent;
      --mdc-snackbar-supporting-text-color: default;
      .mdc-snackbar__surface {
        padding: 0;
        background: transparent;
        box-shadow: none;
        .mat-mdc-snack-bar-label {
          padding: 0;
        }
      }
    }
    &.danger-snack-bar {
      --mdc-snackbar-container-color: var(--app-color-danger);
      --mdc-snackbar-supporting-text-color: var(--app-color-danger-contrast);
    }
    &.success-snack-bar {
      --mdc-snackbar-container-color: var(--app-color-success);
      --mdc-snackbar-supporting-text-color: var(--app-color-success-contrast);
    }
  }
}
