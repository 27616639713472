/** @format */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  mat-slider.mat-mdc-slider {
    .mdc-slider__value-indicator-text {
      white-space: nowrap;
    }

    --mdc-slider-inactive-track-color: var(--app-color-primary);
    --mdc-slider-active-track-color: var(--app-color-primary);
    --mdc-slider-with-tick-marks-active-container-color: var(--app-color-primary-contrast);
    --mdc-slider-handle-color: var(--app-color-primary);
    --mdc-slider-focus-handle-color: var(--app-color-primary);
    --mdc-slider-label-container-color: var(--app-color-primary);
    --mdc-slider-label-label-text-color: var(--app-color-primary-contrast);

    &[color='secondary'] {
      --mdc-slider-inactive-track-color: var(--app-color-secondary);
      --mdc-slider-active-track-color: var(--app-color-secondary);
      --mdc-slider-with-tick-marks-active-container-color: var(--app-color-secondary-contrast);
      --mdc-slider-handle-color: var(--app-color-secondary);
      --mdc-slider-focus-handle-color: var(--app-color-secondary);
      --mdc-slider-label-container-color: var(--app-color-secondary);
      --mdc-slider-label-label-text-color: var(--app-color-secondary-contrast);
    }
  }
}
