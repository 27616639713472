@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  .mat-mdc-table {
    .mat-mdc-header-cell,
    .mat-mdc-cell {
      border-bottom-color: #0000001f;
    }
  }
}
