// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin theme($theme) {
  // Define any styles affected by the theme.

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drop-list:not([has-handle]):not(.cdk-drop-list-disabled) {
    .cdk-drag {
      cursor: move;
    }
  }

  .cdk-drop-list:not(.cdk-drop-list-disabled) .cdk-drag-handle {
    cursor: move;
  }
}
