/** @format */

@mixin theme($theme) {
  div,
  mat-label,
  span,
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  b,
  strong,
  i,
  sup {
    // color: currentColor;
    &[color],
    &[ng-reflect-color] {
      color: var(--app-item-color, currentColor);
    }

    &[uppercase] {
      text-transform: uppercase;
    }

    &[lowercase] {
      text-transform: lowercase;
    }

    &[underline] {
      text-decoration: underline;
    }

    &[line-through] {
      text-decoration: line-through;
    }

    &[nowrap] {
      white-space: nowrap;
    }

    &[thin] {
      font-weight: var(--app-font-weight-thin);
    }
    &[light] {
      font-weight: var(--app-font-weight-light);
    }
    &[regular] {
      font-weight: var(--app-font-weight-regular);
    }
    &[medium] {
      font-weight: var(--app-font-weight-medium);
    }
    &[bold] {
      font-weight: var(--app-font-weight-bold);
    }
    &[black] {
      font-weight: var(--app-font-weight-black);
    }
    &[italic] {
      font-style: italic;
    }

    &[clear-style] {
      * {
        margin: 0;
        padding: 0;
      }
    }
  }
}
