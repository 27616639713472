/** @format */

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin theme($theme) {
  quill-view-html {
    .ql-editor {
      padding: var(--quill-editor-padding, 0);
      overflow: hidden;

      p {
        margin: 0;
      }

      * {
        cursor: inherit;
      }

      a {
        cursor: pointer;

        & > img {
          cursor: pointer;
        }
      }
    }
  }

  a,
  button,
  [routerlinkactive] {
    quill-view-html .ql-editor {
      * {
        cursor: pointer;
      }
    }
  }

  .ql-container {
    font-size: 16px;
  }

  quill-editor {
    display: flex;
    flex-direction: column;

    .ql-container {
      overflow: auto;
      flex: 1 1 100%;
      display: inline-flex;
      min-width: 100%;

      .ql-editor {
        width: 100%;
      }
    }

    .ql-font-weight {
      width: 60px;

      .ql-picker-label:not([data-value]):before,
      .ql-picker-item:not([data-value]):before {
        content: '400';
      }

      .ql-picker-label:before,
      .ql-picker-item:before {
        content: attr(data-value);
        display: inline-block;
        line-height: 22px;
      }

      .ql-picker.ql-size .ql-picker-label:before,
      .ql-picker.ql-size .ql-picker-item:before {
        content: attr(data-value);
      }
    }

    .ql-picker.ql-size {
      .ql-picker-item,
      .ql-picker-label {
        &:before {
          content: attr(data-value) !important;
          font-size: attr(data-value) !important;
        }
      }
    }

    .ql-picker.ql-font {
      .ql-picker-item,
      .ql-picker-label {
        &[data-value='Lato']::before {
          content: 'Lato';
          font-family: 'Lato, sans-serif';
        }
        &[data-value='Roboto Serif']::before {
          content: 'Roboto Serif';
          font-family: 'Roboto Serif, serif';
        }
        &[data-value='roboto']::before {
          content: 'Roboto';
          font-family: 'Roboto';
        }
      }
    }

    &[label] {
      position: relative;
      border-radius: 5px;
      border: 1px solid #ccc;
      margin-top: 5px;
      padding: 1px;

      .ql-toolbar.ql-snow {
        border-top: none;
        border-right: none;
        border-left: none;
      }

      .ql-container.ql-snow {
        border-bottom: none;
        border-right: none;
        border-left: none;
      }

      &:hover {
        transition: border-color 0.2s linear;
      }

      &:hover:not([error]) {
        border-color: black;
      }

      &[error='true'] {
        border-color: var(--app-color-warn);

        &:before {
          color: var(--app-color-warn);
        }
      }

      &:before {
        content: attr(label);
        position: absolute;
        font-size: 12px;
        line-height: 12px;
        z-index: 2;
        top: -6px;
        left: 10px;
        color: rgba(0, 0, 0, 0.6);
        background-color: white;
        padding: 0 4px;
      }

      &[required]:before {
        content: attr(label) ' *';
      }

      &[color='primary'] {
        &:before {
          color: var(--app-color-primary);
        }
      }
    }

    &[locale='fr'] {
      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
        content: 'Petit';
      }

      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
        content: 'Normale';
      }

      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
        content: 'Grand';
      }

      .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before,
      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
        content: 'Énorme';
      }

      .ql-snow .ql-picker.ql-header .ql-picker-label::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: 'Normale';
      }

      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
        content: 'Titre 1';
      }

      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
        content: 'Titre 2';
      }

      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
        content: 'Titre 3';
      }

      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
        content: 'Titre 4';
      }

      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
        content: 'Titre 5';
      }

      .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
      .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
        content: 'Titre 6';
      }
    }
  }

  [quill-html] {
    p,
    ol,
    ul,
    pre,
    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    ol,
    ul {
      padding-left: 1.5em;
    }
  }
}
