/** @format */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  .dialog-edit-v2 {
    --mat-dialog-container-max-width: 90vw;
    --mat-dialog-container-small-max-width: 90vw;
    // .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    //   position: relative;
    //   padding: 30px 2px 0px 16px;
    // }
  }

  .dialog-edit {
    .mat-mdc-dialog-container .mat-mdc-dialog-surface {
      position: relative;
      padding: 30px 2px 0px 16px;
    }
  }

  .info-dialog {
    --mat-dialog-container-max-width: 80vw;
    --mat-dialog-container-small-max-width: 80vw;
  }

  .dialog-no-padding {
    .mat-mdc-dialog-container .mat-mdc-dialog-surface {
      padding: 0;
      mat-dialog-content.mat-mdc-dialog-content {
        margin: 0;
      }
      mat-dialog-actions.mat-mdc-dialog-actions {
        margin-bottom: 0;
      }
    }
  }

  .dialog-no-border-radius {
    .mat-mdc-dialog-container .mat-mdc-dialog-surface {
      --mdc-dialog-container-shape: 0;
    }
  }

  .dialog-no-background {
    .mat-mdc-dialog-container .mat-mdc-dialog-surface {
      background: transparent;
      box-shadow: none;
    }
  }

  .mat-mdc-dialog-container {
    .mat-toolbar[mat-dialog-header] {
      position: sticky;
      top: 0;
      z-index: 100;
      padding: 0;
      height: inherit;
      min-height: var(--mat-toolbar-standard-height);
      max-height: 100px;
      width: 100%;

      button[mat-dialog-close][mat-dialog-back-btn] {
        height: 100%;
        min-height: 100px;
        padding: 0;
        min-width: 0;
        div {
          span {
            font-size: 12px;
            transform: rotateZ(180deg);
            writing-mode: vertical-rl;
          }
          mat-icon {
            font-size: 22px;
            line-height: 22px;
            height: 22px;
            width: 22px;
          }
        }
      }

      button[mat-dialog-close][mat-dialog-close-btn] {
        height: 100%;
        min-height: 100px;
        padding: 8px 16px;
        margin-left: auto;
        .sup {
          font-size: 1em;
          line-height: 1em;
          padding: 4px;
        }
        mat-icon {
          font-size: 30px;
          line-height: 30px;
          height: 30px;
          width: 30px;
        }
      }

      [mat-dialog-header-title] {
        font-size: 50px;
        line-height: 50px;
        margin-left: 8px;

        mat-icon {
          font-size: 50px;
          line-height: 50px;
          height: 50px;
          width: 50px;
        }
      }
    }
  }

  .mat-mdc-dialog-title {
    #close-btn {
      position: absolute;
      right: 0px;
    }
  }
}
