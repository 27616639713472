/** @format */

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin theme($theme) {
  // Define any styles affected by the theme.

  app-datagrid {
    mat-form-field.mat-mdc-form-field {
      font-size: 14px;
      --mdc-filled-text-field-label-text-size: 14px;
    }
    mat-select.mat-mdc-select {
      font-size: 14px;
    }
  }

  .form-field {
    width: 284px;
    min-height: 56px;
    height: fit-content;
    margin: 0 8px 0 0;

    &.disabled,
    &[disabled]:not([disabled='false']) {
      & > mat-label {
        color: rgba(0, 0, 0, 0.38) !important;
      }
    }

    &.mat-mdc-checkbox {
      margin-top: 5px;
      margin-bottom: 24px;
    }

    &.mat-button-toggle-group {
      margin-top: 5px;
    }
  }

  mat-form-field.mat-form-field-appearance-outline.mat-mdc-form-field-has-icon-prefix {
    .mdc-floating-label.mat-mdc-floating-label {
      transform: var(
        --mat-mdc-form-field-label-transform,
        translateY(-50%) translateX(calc(1 * (52px + var(--mat-mdc-form-field-label-offset-x, 0px))))
      ) !important;
    }
  }

  mat-form-field.mat-mdc-form-field {
    .mat-mdc-form-field-text-suffix {
      white-space: nowrap;
    }
    .mat-mdc-form-field-icon-suffix {
      display: flex;
      align-items: center;
    }

    .mat-mdc-form-field-hint-wrapper {
      line-height: 1em;
      color: var(--app-color-medium);

      .mat-mdc-form-field-hint {
        &::before {
          height: 1em;
        }
      }
    }

    &:hover .mat-mdc-form-field-focus-overlay,
    &.mat-focused .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }

    &.form-field.mat-form-field-appearance-outline {
      margin-top: 5px;
    }

    &.mat-form-field-appearance-fill {
      border-radius: 0;
      .mat-mdc-text-field-wrapper {
        border-radius: 0;
      }
      .mat-mdc-text-field-wrapper {
        background-color: transparent;
        padding: 0 2px;
      }
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      &.mdc-text-field--disabled {
        .mat-mdc-form-field-icon-prefix {
          color: var(--mdc-outlined-text-field-disabled-input-text-color);
        }
      }

      .mat-mdc-form-field-infix {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        place-content: center flex-start;
        align-items: center;
        flex-direction: row;

        mat-select.mat-mdc-select {
          display: flex;
          .mat-mdc-select-value-text {
            line-height: 36px;
          }
        }
      }
    }

    // HAS PREFIX
    &[has-prefix],
    &.has-prefix {
      .prefix-top {
        position: absolute;
        top: 5px;
        font-size: 0.7em;
      }

      &.mat-form-field-disabled mat-label[matprefix] {
        color: rgba(0, 0, 0, 0.38);
      }

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
        padding-top: 22px;
        padding-bottom: 10px;
      }
    }

    // NO SUBSCRIPT
    &[no-subscript],
    &.no-subscript {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      &.mat-form-field-appearance-fill {
        .mat-mdc-text-field-wrapper,
        .mat-mdc-form-field-flex {
          align-items: center;
        }

        .mat-mdc-form-field-infix {
          min-height: unset;
          padding: 12px 0;
        }
        .mat-mdc-floating-label {
          padding-bottom: 8px;
        }
        &.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
          transform: none;
        }
      }
    }

    // NO line ripple
    &[no-line-ripple],
    &.no-line-ripple {
      .mdc-line-ripple {
        display: none;
      }
    }

    .form-field-clear-btn {
      --app-item-color: var(--app-color-warn);
      margin-right: 4px;
    }

    mat-error.mat-mdc-form-field-error {
      display: flex;
      align-items: center;
      font-size: 0.8em;
      white-space: nowrap;
    }
  }

  .form-block {
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
    padding: 20px 12px 12px 12px;

    &:hover {
      transition: border-color 0.2s linear;
    }
    &:hover:not([error]) {
      border-color: black;
    }

    &[error='true'] {
      border-color: var(--app-color-warn);

      &:before {
        color: var(--app-color-warn);
      }
    }

    &:before {
      content: attr(label);
      position: absolute;
      font-size: 12px;
      line-height: 12px;
      z-index: 2;
      top: -6px;
      left: 10px;
      color: var(--mdc-outlined-text-field-label-text-color);
      padding: 0 4px;
      max-width: calc(100% - 24px);
      overflow: hidden;
    }

    &:after {
      content: attr(label);
      position: absolute;
      font-size: 12px;
      z-index: 1;
      top: -1px;
      left: 10px;
      height: 1px;
      color: transparent;
      background-color: white;
      padding: 0 4px;
      max-width: calc(100% - 24px);
      overflow: hidden;
    }

    &.disabled,
    &[disabled]:not([disabled='false']) {
      &:before {
        color: var(--mdc-outlined-text-field-disabled-label-text-color);
      }
    }

    #error {
      position: absolute;
      right: 8px;
      bottom: 0px;
      color: var(--app-color-warn);
      font-size: 0.8em;
      white-space: nowrap;
    }

    &[required]:before,
    &[required]:after {
      content: attr(label) ' *';
    }
  }

  input::placeholder {
    font-size: 0.8em;
  }

  .form-field.form-field[xsmall],
  .form-field.form-field[size='xsmall'] {
    min-width: 75px;
    width: auto !important;
    .mat-mdc-form-field-infix {
      width: 75px;
    }
  }

  .form-field.form-field[small],
  .form-field.form-field[size='small'] {
    min-width: 150px;
    width: auto !important;
    .mat-mdc-form-field-infix {
      width: 150px;
    }
  }

  .form-field.form-field[expand],
  .form-field.form-field[size='expand'] {
    min-width: 100%;
    width: 100%;
    .mat-mdc-form-field-infix {
      width: 100%;
    }
  }
}
