/** @format */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  .mat-mdc-autocomplete-panel {
    .mat-mdc-option {
      .option-prefix-top {
        font-size: 0.8em;
        line-height: 0.8em;
      }
    }
  }
}
