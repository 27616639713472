/** @format */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  mat-checkbox.mat-mdc-checkbox {
    .mdc-checkbox {
      // width: var(--mdc-checkbox-state-layer-size);
      // height: var(--mdc-checkbox-state-layer-size);
      // flex: 0 0 var(--mdc-checkbox-state-layer-size);
    }
    .mat-mdc-checkbox-touch-target {
      z-index: 10;
      width: calc(var(--mdc-checkbox-state-layer-size) + 8px);
      height: calc(var(--mdc-checkbox-state-layer-size) + 8px);
    }
  }
}
