/** @format */

@mixin theme($theme) {
  ngx-file-drop {
    &[disabled='true'],
    &[ng-reflect-disabled='true'] {
      pointer-events: none;
    }

    &[label] {
      position: relative;
      display: inline-block;
      border-radius: 5px;
      border: 1px solid #ccc;
      height: 100%;

      &[no-subscript] {
        margin-bottom: 0;
        height: 100%;
      }

      .ngx-file-drop__drop-zone {
        border: none;
        border-radius: 0;
        min-height: var(--file-drop-min-height, 100px);
        height: 100%;
        .ngx-file-drop__content {
          min-height: var(--file-drop-min-height, 100px);
          height: 100%;
          cursor: pointer;
        }
      }

      &:hover,
      &[error='true'] {
        transition: border-color 0.2s linear;
      }

      &:hover:not([error]) {
        border-color: black;
      }

      &[error='true'] {
        border-color: var(--app-color-warn);

        &:before {
          color: var(--app-color-warn);
        }
      }

      &:before {
        content: attr(label);
        position: absolute;
        font-size: 12px;
        z-index: 2;
        top: -12px;
        left: 10px;
        color: rgba(0, 0, 0, 0.6);
        padding: 0 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 24px);
        overflow: hidden;
      }

      &[required]:not([required='false']):before {
        content: attr(label) ' *';
      }

      &:after {
        content: attr(label);
        position: absolute;
        font-size: 12px;
        z-index: 1;
        top: -1px;
        left: 10px;
        height: 1px;
        background: white;
        padding: 0 4px;
        color: transparent;
        max-width: calc(100% - 24px);
        overflow: hidden;
      }

      &[disabled='true'],
      &[ng-reflect-disabled='true'] {
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0.06);
        &:before {
          color: rgba(0, 0, 0, 0.38);
        }
      }

      &[color='primary'] {
        &:before {
          color: var(--app-color-primary);
        }
      }

      #error {
        text-align: end;
        padding: 0 8px;
        color: var(--app-color-danger);
      }
    }
  }
}
