// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin theme($theme) {
  // Define any styles affected by the theme.

  mat-fab-menu {
    display: inline-block !important;
    width: fit-content;

    .container {
      width: fit-content;

      & > div {
        width: fit-content;
        margin: 5px;

        button {
          margin: 4px !important;
        }

        .mat-mdc-fab {
          padding: 2px;
          width: 28px !important;
          height: 28px !important;
          line-height: 24px !important;
        }

        .mat-mdc-focus-indicator {
          margin: 0 !important;

          width: 24px !important;
          height: 24px !important;
          line-height: 24px !important;
          .mat-mdc-button-wrapper {
            display: block;
            height: 24px;
            width: 24px;
            line-height: 1;
            padding: 2px;
          }

          mat-icon.mat-icon {
            height: 20px;
            width: 20px;
            line-height: 20px;
            font-size: 18px;
            display: block;
          }
        }
      }
    }

    .mat-mdc-tooltip-trigger {
      overflow: hidden;
      border: 1px solid var(--app-color-primary);
      .mat-mdc-button-wrapper {
        padding: 0;
      }
    }

    &[direction="top"] {
      .container > div:first-child {
        margin-top: 0px !important;
      }
      .container > div:not(:first-child) button.mat-mdc-focus-indicator {
        margin-top: 8px !important;
      }
    }

    &[direction="right"] {
      .container > div:first-child {
        margin-right: 8px !important;
      }
      .container > div:not(:first-child) button.mat-mdc-focus-indicator {
        margin-right: 8px !important;
      }
    }

    &[direction="bottom"] {
      .container > div:first-child {
        margin-bottom: 8px !important;
      }
      .container > div:not(:first-child) button.mat-mdc-focus-indicator {
        margin-bottom: 8px !important;
      }
    }

    &[direction="left"] {
      .container > div:first-child {
        margin-left: 8px !important;
      }
      .container > div:not(:first-child) button.mat-mdc-focus-indicator {
        margin-left: 8px !important;
      }
    }
  }
}
