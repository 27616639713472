/** @format */

// Import library functions for theme creation.

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin theme($theme) {
  // Define any styles affected by the theme.

  .datagrid-colum-options-item {
    padding: 0 16px;
    height: 48px;
    line-height: 1em;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    cursor: auto;
    background-color: white;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }

  .mat-mdc-menu-panel {
    &.elevator-info-actions {
      max-width: 400px;
    }
  }

  .mat-mdc-menu-panel.notifications {
    min-width: 332px;
    max-width: auto;
  }

  .mat-mdc-menu-panel.options-hfill {
    .mat-mdc-menu-item {
      width: 100%;
      .mat-mdc-menu-item-text {
        width: 100%;
      }
    }
  }

  .mat-mdc-menu-panel.no-max-width {
    max-width: unset;
  }
}
